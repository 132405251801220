import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import translationEN from "./locales/en/translation.json";
import translationKO from "./locales/ko/translation.json";
import translationHI from "./locales/hi/translation.json";
import translationPT from "./locales/pt/translation.json";
import translationES from "./locales/es/translation.json";

const resources = {
  en: {
    translation: translationEN
  },
  ko: {
    translation: translationKO
  },
  hi: {
    translation: translationHI
  },
  pt: {
    translation: translationPT
  },
  es: {
    translation: translationES
  }
};

const i18nextLng = localStorage.getItem('i18nextLng') || 'ko';

i18n
  .use(Backend)
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    detection: { order: ['path', 'navigator'] },
    fallbackLng: i18nextLng,
    resources,
    lng: i18nextLng,
    // debug: true,

    // interpolation: {
    //     escapeValue: false, // not needed for react as it escapes by default
    // }
  });

export default i18n;
import { createStore } from 'redux'

const initialState = {
  logined:  true,
  isMobile: false,
  pathname: '',
  stop:     0,
  alarmCnt: 0,
  muted:    true,
  mem_type: 1,
  mainList:  [],
  aigeeList: [],
  alarmList: [],
  searchList:[],
  likeList:  [],
  mycontentList: [],
  usercontentList:[],
}

const changeState = (state = initialState, action) => {
  switch (action.type) {
    case 'updateIsMobile':
      return {
        ...state,
        isMobile: action.isMobile
      };
    case 'updatePathname':
      return {
        ...state,
        pathname: action.pathname
      };
    case 'updateMemStop':
      return {
        ...state,
        stop: action.stop
      };
    case 'updateAlarmCnt':
      return {
        ...state,
        alarmCnt: action.alarmCnt
      };
    case 'updateMuted':
      return {
        ...state,
        muted: action.muted
      };
    case 'updateMemtype':
      return {
        ...state,
        mem_type: action.mem_type
      };
    case 'updateMainList':
      return {
        ...state,
        mainList: action.mainList
      };
    case 'updateAigeeList':
      return {
        ...state,
        aigeeList: action.aigeeList
      };
    case 'updateAlarmList':
      return {
        ...state,
        alarmList: action.alarmList
      };
    case 'updateSearchList':
      return {
        ...state,
        searchList: action.searchList
      };
    case 'updateLikeList':
      return {
        ...state,
        likeList: action.likeList
      };
    case 'updateMyContentList':
      return {
        ...state,
        mycontentList: action.mycontentList
      };
    case 'updateUserContentList':
      return {
        ...state,
        usercontentList: action.usercontentList
      };
    default:
      return state
  }
}

const store = createStore(changeState)
export default store
